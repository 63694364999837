import {
  Alert,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@addglowapp/components';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { BrandRowFragment } from '@src/generated/graphql';

interface Props {
  items: BrandRowFragment[];
}

function BrandTable({ items }: Props): JSX.Element {
  if (!items.length) {
    return <Alert type="info">No Brands found.</Alert>;
  }

  const sortedItems = _.sortBy(items, 'name');

  return (
    <Table className="max-w-4xl">
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Community Domain</TableHead>
          <TableHead>Created At</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedItems.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <Link to={`${item.id}/show`} className="font-medium">
                {item.name}
              </Link>
            </TableCell>
            <TableCell>{item.communityDomain}</TableCell>
            <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default BrandTable;
