import { ConfirmDialog, Toaster } from '@addglowapp/components';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '../components/ErrorBoundary';
import PagesRoot from '../pages';
import { config } from '../services/config';
import AppApolloProvider from './AppApolloProvider';

function App(): JSX.Element {
  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={config.VITE_AUTH0_DOMAIN}
        clientId={config.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/auth/auth0-callback`,
          audience: config.VITE_AUTH0_AUDIENCE,
          connection: 'email',
          prompt: 'none',
        }}
        skipRedirectCallback
      >
        <AppApolloProvider>
          <BrowserRouter>
            <PagesRoot />
            <Toaster />
            <ConfirmDialog />
          </BrowserRouter>
        </AppApolloProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

export default App;
