import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../../NotFound.page';
import BrandCreatePage from './edit/create.page';
import BrandEditPage from './edit/edit.page';
import BrandListPage from './list/index.page';
import { BrandShowPage } from './show/BrandShow.page';

function BrandsFeatureRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path=":id/edit" element={<BrandEditPage />} />
      <Route path=":id/show" element={<BrandShowPage />} />
      <Route path="new" element={<BrandCreatePage />} />
      <Route index element={<BrandListPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default BrandsFeatureRoutes;
