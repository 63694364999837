import { Button, TextInputField } from '@addglowapp/components';
import { Card } from '@addglowapp/components';
import { clsx } from 'clsx';
import { BrandShowFragment } from '@src/generated/graphql';
import { useBrandEditForm } from './useBrandEditForm';

interface BrandShowDetailsProps {
  className?: string;
  brand: BrandShowFragment;
}

export function BrandShowDetails({
  className,
  brand,
}: BrandShowDetailsProps): JSX.Element {
  const {
    handleSubmit,
    form: {
      control,
      formState: { isDirty },
    },
  } = useBrandEditForm({ id: brand.id, initialData: brand });
  return (
    <Card className={clsx('p-4', className)}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h3 className="font-semibold">Details</h3>
        <TextInputField.Controller label="Name" control={control} name="name" />
        <TextInputField.Controller
          label="Shop URL"
          control={control}
          name="shopUrl"
          description='Used in the "Shop" button in the community header.'
        />
        <TextInputField.Controller
          label="Community Domain"
          control={control}
          name="communityDomain"
        />
        <Button type="submit" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </Card>
  );
}
