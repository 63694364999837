import { ErrorableLoader } from '@addglowapp/components';
import { MdChevronRight } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { useBrandShowByIdQuery } from '@src/generated/graphql';
import { BrandShowCustomAuth } from './BrandShowCustomAuth';
import { BrandShowDangerZone } from './BrandShowDangerZone';
import { BrandShowDetails } from './BrandShowDetails';
import { BrandShowHeader } from './BrandShowHeader';
import { BrandShowSettings } from './BrandShowSettings';
import { BrandShowTrialSettings } from './BrandShowTrialSettings';

export function BrandShowPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useBrandShowByIdQuery({
    variables: { id: id ?? '' },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-6xl space-y-4">
      <div className="flex items-center gap-2 border-b pb-4">
        <Link to="/brands/brands" className="text-black hover:underline">
          <h3>Brands</h3>
        </Link>
        <MdChevronRight className="size-4" />
        <h3>{data.brand.name}</h3>
      </div>
      <BrandShowHeader brand={data.brand} />
      <div className="grid grid-cols-2 items-start gap-4">
        <BrandShowDetails brand={data.brand} />
        <BrandShowSettings brand={data.brand} />
        <BrandShowCustomAuth brand={data.brand} />
        <BrandShowTrialSettings brand={data.brand} />
        <BrandShowDangerZone brand={data.brand} />
      </div>
    </div>
  );
}
