import { Button, TextInputField, SwitchField } from '@addglowapp/components';
import { Card } from '@addglowapp/components';
import { clsx } from 'clsx';
import { BrandShowFragment } from '@src/generated/graphql';
import { useBrandEditForm } from './useBrandEditForm';

interface BrandShowSettingsProps {
  className?: string;
  brand: BrandShowFragment;
}

export function BrandShowSettings({
  className,
  brand,
}: BrandShowSettingsProps): JSX.Element {
  const {
    handleSubmit,
    form: {
      control,
      formState: { isDirty },
    },
  } = useBrandEditForm({ id: brand.id, initialData: brand });

  return (
    <Card className={clsx('p-4', className)}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h3 className="font-semibold">Settings</h3>
        <SwitchField.Controller
          label="Enable phone verification"
          control={control}
          name="isPhoneVerificationEnabled"
          description="Require users to verify their phone number before redeeming their rewards."
        />
        <SwitchField.Controller
          label="Show PWA Prompts"
          control={control}
          name="shouldShowPWA"
          description="Show PWA Install prompt to community members."
        />
        <TextInputField.Controller
          label="Analytics Key"
          control={control}
          name="analyticsKey"
          description="Used for identifying the brand in analytics."
        />
        <Button type="submit" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </Card>
  );
}
