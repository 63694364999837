import { Button, SwitchField } from '@addglowapp/components';
import { Card } from '@addglowapp/components';
import { clsx } from 'clsx';
import { BrandShowFragment } from '@src/generated/graphql';
import { BrandShowCustomAuthForm } from './BrandShowCustomAuthForm';
import { useBrandEditForm } from './useBrandEditForm';

interface BrandShowCustomAuthProps {
  className?: string;
  brand: BrandShowFragment;
}

export function BrandShowCustomAuth({
  className,
  brand,
}: BrandShowCustomAuthProps): JSX.Element {
  const {
    handleSubmit,
    form: {
      control,
      formState: { isDirty },
      watch,
      setValue,
    },
  } = useBrandEditForm({ id: brand.id, initialData: brand });

  const usesCustomAuth = watch('usesCustomAuth');

  return (
    <Card className={clsx('p-4', className)}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h3 className="font-semibold">Custom Auth Settings</h3>
        <SwitchField.Controller
          label="Uses custom authentication"
          control={control}
          name="usesCustomAuth"
          description="Use brand's identity provider instead of AddGlow's."
        />
        {usesCustomAuth && (
          <BrandShowCustomAuthForm control={control} setValue={setValue} />
        )}
        <Button type="submit" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </Card>
  );
}
