import { getFullCommunityDomain } from '@addglowapp/shared';
import { clsx } from 'clsx';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { BrandShowFragment } from '@src/generated/graphql';

interface BrandShowHeaderProps {
  className?: string;
  brand: BrandShowFragment;
}

function Statistic({
  label,
  value,
}: {
  label: string;
  value: string | number;
}): JSX.Element {
  return (
    <div className="flex flex-col items-center space-y-1">
      <div className="text-sm text-gray-500">{label}</div>
      <div className="text-lg font-semibold">{value}</div>
    </div>
  );
}

export function BrandShowHeader({
  className,
  brand,
}: BrandShowHeaderProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex justify-between rounded-lg border px-4 py-8',
        className,
      )}
    >
      <div className="flex items-center space-x-4">
        {brand.logoFile?.hostedUrl && (
          <img
            src={brand.logoFile.hostedUrl}
            alt="Brand logo"
            className="size-14 rounded-md"
          />
        )}
        <div className="flex-col gap-2">
          <div className="text-lg font-semibold">{brand.name}</div>
          <p>
            <a
              href={getFullCommunityDomain(brand.communityDomain)}
              className="flex items-center gap-1 text-sm text-gray-500"
              target="_blank"
              rel="noreferrer"
            >
              {brand.communityDomain}
              <FaExternalLinkAlt className="size-3" />
            </a>
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <Statistic label="Members" value={brand.memberCount} />
        <Statistic label="Posts" value={brand.postCount} />
      </div>
    </div>
  );
}
