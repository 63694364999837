import { ErrorableLoader } from '@addglowapp/components';
import {
  GetUsersDocument,
  UserRowFragment,
  useDeleteUserMutation,
  useGetUsersQuery,
} from '@src/generated/graphql';
import UserTable from './UserTable';

function UserListPage(): JSX.Element {
  const { data, error } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [{ query: GetUsersDocument }],
  });

  const handleDeleteItem = async (item: UserRowFragment): Promise<void> => {
    await deleteUser({
      variables: { input: { id: item.id } },
    });
  };

  return (
    <div className="space-y-4">
      <h1>Users</h1>

      {!data ? (
        <ErrorableLoader error={error} />
      ) : (
        <UserTable deleteItem={handleDeleteItem} items={data.users} />
      )}
    </div>
  );
}

export default UserListPage;
