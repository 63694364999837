import { Accordion, TextInputField, SwitchField } from '@addglowapp/components';
import { clsx } from 'clsx';
import { useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BrandFormData } from '../edit/brand-schema';

interface BrandShowCustomAuthFormProps {
  className?: string;
  control: Control<BrandFormData>;
  setValue: UseFormSetValue<BrandFormData>;
}

export function BrandShowCustomAuthForm({
  className,
  control,
  setValue,
}: BrandShowCustomAuthFormProps): JSX.Element {
  const customOAuth = useWatch({ control, name: 'customOauth' });
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  return (
    <div className={clsx('space-y-2', className)}>
      <h4>Custom Authentication</h4>
      <SwitchField
        onChange={(value) => {
          if (value) {
            setValue('customOauth', {
              clientId: '',
              clientSecret: '',
              authorizeUrl: '',
              accessUrl: '',
              jwksEndpointUrl: '',
              jwtIssuer: '',
            });
            setIsAccordionExpanded(true);
          } else {
            setValue('customOauth', null);
          }
        }}
        value={!!customOAuth}
        label="Use OAuth login"
        description="Use brand's OAuth server as an identity provider."
      />
      {customOAuth ? (
        <Accordion
          type="single"
          collapsible
          value={isAccordionExpanded ? 'oauth' : undefined}
          onValueChange={(value) => setIsAccordionExpanded(value === 'oauth')}
        >
          <Accordion.Item value="oauth">
            <Accordion.Trigger>OAuth Settings</Accordion.Trigger>
            <Accordion.Content className="space-y-2">
              <TextInputField.Controller
                label="Client ID"
                control={control}
                name="customOauth.clientId"
              />
              <TextInputField.Controller
                label="Client Secret"
                control={control}
                name="customOauth.clientSecret"
              />
              <TextInputField.Controller
                label="Authorize URL"
                control={control}
                name="customOauth.authorizeUrl"
              />
              <TextInputField.Controller
                label="Access URL"
                control={control}
                name="customOauth.accessUrl"
              />
              <TextInputField.Controller
                label="JWKs Endpoint URL"
                control={control}
                name="customOauth.jwksEndpointUrl"
              />
              <TextInputField.Controller
                label="JWT Issuer"
                control={control}
                name="customOauth.jwtIssuer"
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <>
          <TextInputField.Controller
            label="Login URL"
            control={control}
            name="customAuthUrl"
          />
        </>
      )}
      <TextInputField.Controller
        label="Log Out URL"
        control={control}
        name="customLogoutUrl"
      />
      <TextInputField.Controller
        label="User Settings URL"
        control={control}
        name="customUserSettingsUrl"
      />
    </div>
  );
}
