import { BackButton, ErrorableLoader, toast } from '@addglowapp/components';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBrandEditByIdQuery,
  useUpdateBrandMutation,
} from '@src/generated/graphql';
import { BrandFormData } from './brand-schema';
import BrandEditForm from './BrandEditForm';

function BrandEditPage(): JSX.Element {
  const { id } = useParams() as { id: string };

  const { data, error } = useBrandEditByIdQuery({
    variables: { id },
  });

  const initialData: BrandFormData | undefined = useMemo(() => {
    if (!data?.brand) return undefined;
    return data.brand;
  }, [data]);

  const [updateBrand] = useUpdateBrandMutation();
  const navigate = useNavigate();

  if (!initialData) {
    return <ErrorableLoader error={error} />;
  }

  const submitData = async (formData: BrandFormData): Promise<void> => {
    await updateBrand({
      variables: { input: { id, data: formData } },
    });
    toast.success('Successfully updated item!');
    navigate('..');
  };

  return (
    <div className="space-y-4">
      <h1 className="flex space-x-2">
        <BackButton />
        <span>Edit Brand ({id})</span>
      </h1>
      <BrandEditForm submitData={submitData} initialData={initialData} />
    </div>
  );
}

export default BrandEditPage;
