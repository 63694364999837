import {
  Alert,
  Button,
  TextInputField,
  useStatus,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { EmbeddedObjectFormProps } from '@src/components/EmbeddedObjectInput';
import { logAndFormatError } from '@src/services/error-formatter';
import {
  EmbeddedCustomOauthFormData,
  embeddedCustomOauthFormSchema,
} from './brand-schema';

export function EmbeddedCustomOauthForm({
  initialData,
  onSubmit,
}: EmbeddedObjectFormProps<EmbeddedCustomOauthFormData>): JSX.Element {
  const { handleSubmit, control } = useForm<EmbeddedCustomOauthFormData>({
    resolver: zodResolver(embeddedCustomOauthFormSchema),
    defaultValues: initialData,
  });
  const { status, setError } = useStatus();

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e).catch((err) =>
          setError(logAndFormatError(err)),
        );
      }}
      className="space-y-4"
    >
      <Alert.WithStatus status={status} />
      <TextInputField.Controller
        label="Client ID"
        control={control}
        name="clientId"
      />
      <TextInputField.Controller
        label="Client Secret"
        control={control}
        name="clientSecret"
      />
      <TextInputField.Controller
        label="Authorize URL"
        control={control}
        name="authorizeUrl"
      />
      <TextInputField.Controller
        label="Access URL"
        control={control}
        name="accessUrl"
      />
      <TextInputField.Controller
        label="JWKs Endpoint URL"
        control={control}
        name="jwksEndpointUrl"
      />
      <TextInputField.Controller
        label="JWT Issuer"
        control={control}
        name="jwtIssuer"
      />
      <Button type="submit">Update</Button>
    </form>
  );
}
