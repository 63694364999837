import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../../../NotFound.page';
import UserEditPage from './edit/edit.page';
import UserListPage from './list/index.page';

function UsersFeatureRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path=":id/edit" element={<UserEditPage />} />
      <Route index element={<UserListPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default UsersFeatureRoutes;
