import { CurrentUserFragment, useGetUserByIdQuery } from '../generated/graphql';
import { useSession } from './useSession';

interface UseCurrentUserResult {
  user?: CurrentUserFragment;
  loading: boolean;
  error?: Error | null;
}

export function useCurrentUser(): UseCurrentUserResult {
  const { userId } = useSession();
  const { data, loading, error } = useGetUserByIdQuery({
    variables: { id: userId ?? '' },
    skip: !userId,
  });

  const noUserError =
    data && data.user === null ? new Error('No user found') : null;

  return {
    user: data?.user ?? undefined,
    loading,
    error: error ?? noUserError,
  };
}
