import { Button, Dialog, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { Card } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  BrandShowFragment,
  useDeleteBrandMutation,
} from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';

interface BrandShowDangerZoneProps {
  className?: string;
  brand: BrandShowFragment;
}

const confirmDeleteSchema = z.object({
  confirm: z.string().refine((value) => value === 'DELETE', {
    message: 'Confirmation must be DELETE',
  }),
});

export function BrandShowDangerZone({
  className,
  brand,
}: BrandShowDangerZoneProps): JSX.Element {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ confirm: '' }>({
    defaultValues: { confirm: '' },
    resolver: zodResolver(confirmDeleteSchema),
  });

  const [deleteBrand] = useDeleteBrandMutation();
  const navigate = useNavigate();

  const onSubmit = (): Promise<void> => {
    return deleteBrand({ variables: { input: { id: brand.id } } })
      .then(() => {
        toast.success('Successfully deleted brand!');
        setIsDeleteModalOpen(false);
        navigate('/brands/brands');
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };

  return (
    <Card className={clsx('space-y-4 p-4', className)}>
      <h3 className="font-semibold">Danger Zone</h3>
      <Dialog
        open={isDeleteModalOpen}
        onOpenChange={(open) => {
          setIsDeleteModalOpen(open);
          if (!open) {
            reset();
          }
        }}
      >
        <Dialog.Trigger asChild>
          <Button color="red">Delete Brand</Button>
        </Dialog.Trigger>
        <Dialog.Content>
          <Dialog.Header>
            <Dialog.Title>Delete {brand.name}</Dialog.Title>
          </Dialog.Header>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <p>
              Are you sure you want to delete this brand? This action is
              <strong> irreversible</strong>.
            </p>
            <TextInputField.Controller
              control={control}
              label="Type DELETE to confirm"
              name="confirm"
            />
            <Dialog.Footer className="flex gap-4">
              <Button color="red" type="submit" disabled={isSubmitting}>
                Delete
              </Button>
              <Dialog.Close asChild>
                <Button>Cancel</Button>
              </Dialog.Close>
            </Dialog.Footer>
          </form>
        </Dialog.Content>
      </Dialog>
    </Card>
  );
}
