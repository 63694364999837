import {
  Alert,
  Button,
  TextInputField,
  useStatus,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import EmbeddedListInput from '@src/components/EmbeddedListInput';
import { BrandMemberBrandOptionFragment } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import {
  EmbeddedBrandMembershipsForm,
  EmbeddedBrandMembershipsTable,
} from './EmbeddedBrandMembershipsForm';
import { UserFormData, userEditFormSchema } from './user-schema';

interface Props {
  className?: string;
  initialData?: Partial<UserFormData>;
  submitData: (data: UserFormData) => Promise<void>;
  brandMemberBrandOptions: BrandMemberBrandOptionFragment[];
}

function UserEditForm({
  className,
  initialData,
  submitData,
  brandMemberBrandOptions,
}: Props): JSX.Element {
  const { handleSubmit, control } = useForm<UserFormData>({
    resolver: zodResolver(userEditFormSchema),
    defaultValues: initialData,
  });
  const { status, setError } = useStatus();
  const [isUpdating, setIsUpdating] = useState(false);

  const onSubmit = async (data: UserFormData): Promise<void> => {
    try {
      setIsUpdating(true);
      await submitData(data);
    } catch (err) {
      setError(logAndFormatError(err));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Alert.WithStatus status={status} />
        <TextInputField.Controller
          label="username"
          control={control}
          name="username"
        />
        <TextInputField.Controller
          label="First Name"
          control={control}
          name="firstName"
        />
        <TextInputField.Controller
          label="Last Name"
          control={control}
          name="lastName"
        />
        <EmbeddedListInput.LabelledController
          label="Brand Memberships"
          control={control}
          name="brandMemberships"
          renderForm={(formProps) => (
            <EmbeddedBrandMembershipsForm
              {...formProps}
              brandMemberBrandOptions={brandMemberBrandOptions}
            />
          )}
          renderTable={(tableProps) => (
            <EmbeddedBrandMembershipsTable
              {...tableProps}
              brandMemberBrandOptions={brandMemberBrandOptions}
            />
          )}
        />
        <Button type="submit" disabled={isUpdating}>
          Save
        </Button>
      </form>
    </div>
  );
}

export default UserEditForm;
