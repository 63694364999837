import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useUpdateBrandMutation } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandFormData, brandEditFormSchema } from '../edit/brand-schema';

interface UseBrandEditFormInput {
  id: string;
  initialData: BrandFormData;
}

export function useBrandEditForm({ id, initialData }: UseBrandEditFormInput): {
  form: UseFormReturn<BrandFormData>;
  handleSubmit: () => Promise<void>;
} {
  const form = useForm<BrandFormData>({
    resolver: zodResolver(brandEditFormSchema),
    values: initialData,
  });
  const [updateBrand] = useUpdateBrandMutation();

  const onSubmit = (formData: BrandFormData): Promise<void> => {
    return updateBrand({
      variables: { input: { id, data: formData } },
    })
      .then(() => {
        toast.success('Successfully updated brand!');
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };

  return { form, handleSubmit: form.handleSubmit(onSubmit) };
}
