import { BackButton, toast } from '@addglowapp/components';
import { useNavigate } from 'react-router-dom';
import {
  GetBrandsDocument,
  useCreateBrandMutation,
} from '@src/generated/graphql';
import { BrandFormData } from './brand-schema';
import BrandEditForm from './BrandEditForm';

function BrandCreatePage(): JSX.Element {
  const [createBrand] = useCreateBrandMutation({
    refetchQueries: [{ query: GetBrandsDocument }],
  });

  const navigate = useNavigate();

  const submitData = async (formData: BrandFormData): Promise<void> => {
    await createBrand({
      variables: { input: { data: formData } },
    });
    toast.success('Successfully created item!');
    navigate('..');
  };

  return (
    <div className="space-y-4">
      <h1 className="flex space-x-2">
        <BackButton />
        <span>Create New Brand</span>
      </h1>
      <BrandEditForm submitData={submitData} />
    </div>
  );
}

export default BrandCreatePage;
