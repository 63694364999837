import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFound.page';
import BrandsRoutes from './brands';

function BrandsFeatureRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="brands/*" element={<BrandsRoutes />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default BrandsFeatureRoutes;
