import { Badge, toast, useConfirmDialog } from '@addglowapp/components';
import {
  Alert,
  LinkButton,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@addglowapp/components';
import { Link } from 'react-router-dom';
import { UserRowFragment } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';

interface Props {
  items: UserRowFragment[];
  deleteItem: (item: UserRowFragment) => Promise<void>;
}

function UserTable({ items, deleteItem }: Props): JSX.Element {
  const { requestConfirm } = useConfirmDialog();

  async function handleDelete(item: UserRowFragment): Promise<void> {
    requestConfirm({
      title: 'Delete Item',
      content: `Are you sure you want to delete this item?`,
      onConfirm: () => {
        deleteItem(item)
          .then(() => {
            toast.success('Successfully deleted the item!');
          })
          .catch((err) => {
            toast.error(
              logAndFormatError(err, 'Sorry we could not delete the item.'),
            );
          });
      },
    });
  }

  if (!items.length) {
    return <Alert type="info">No Users found.</Alert>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead>Username</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell className="flex gap-2">
              <div>{item.email}</div>
              {item.customAuthBrand?.name && (
                <Badge>{item.customAuthBrand?.name}</Badge>
              )}
            </TableCell>
            <TableCell>{item.username}</TableCell>
            <TableCell className="space-x-4">
              <Link to={`${item.id}/edit`}>Edit</Link>
              <LinkButton negative onClick={() => handleDelete(item)}>
                Delete
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default UserTable;
