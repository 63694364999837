import {
  Alert,
  CheckedInput,
  TextInputField,
  Button,
  useStatus,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import EmbeddedObjectInput from '@src/components/EmbeddedObjectInput';
import { ReactDatePickerInput } from '@src/components/ReactDatePickerInput/ReactDatePickerInput';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandFormData, brandEditFormSchema } from './brand-schema';
import { EmbeddedCustomOauthForm } from './EmbeddedCustomOauthForm';
interface Props {
  className?: string;
  initialData?: Partial<BrandFormData>;
  submitData: (data: BrandFormData) => Promise<void>;
}

function BrandEditForm({
  className,
  initialData,
  submitData,
}: Props): JSX.Element {
  const { handleSubmit, control } = useForm<BrandFormData>({
    resolver: zodResolver(brandEditFormSchema),
    defaultValues: initialData,
  });
  const { status, setError } = useStatus();
  const [isUpdating, setIsUpdating] = useState(false);

  const onSubmit = async (data: BrandFormData): Promise<void> => {
    try {
      setIsUpdating(true);
      await submitData(data);
    } catch (err) {
      setError(logAndFormatError(err));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Alert.WithStatus status={status} />
        <TextInputField.Controller label="Name" control={control} name="name" />
        <TextInputField.Controller
          label="Header Button URL (Shop URL)"
          control={control}
          name="shopUrl"
        />
        <TextInputField.Controller
          label="Community Domain"
          control={control}
          name="communityDomain"
        />
        <TextInputField.Controller
          label="Analytics Key"
          control={control}
          name="analyticsKey"
        />
        <CheckedInput.LabelledController
          label="Is Restricted (uncheck to approve)"
          control={control}
          name="isRestricted"
        />
        <CheckedInput.LabelledController
          label="Is Trial"
          control={control}
          name="isTrial"
        />
        <ReactDatePickerInput.LabelledController
          label="Trial End Period"
          control={control}
          name="trialEndsAt"
          showTimeSelect
        />
        <CheckedInput.LabelledController
          label="Require phone verification for redemptions"
          control={control}
          name="isPhoneVerificationEnabled"
        />
        <CheckedInput.LabelledController
          label="Uses Custom Auth?"
          control={control}
          name="usesCustomAuth"
        />
        <TextInputField.Controller
          label="Login URL for Custom Auth (if not using OAuth)"
          control={control}
          name="customAuthUrl"
        />
        <TextInputField.Controller
          label="Custom Auth Logout URL (optional)"
          control={control}
          name="customLogoutUrl"
        />
        <TextInputField.Controller
          label="Custom User Settings URL (optional)"
          control={control}
          name="customUserSettingsUrl"
        />
        <CheckedInput.LabelledController
          label="shouldShowPWA"
          control={control}
          name="shouldShowPWA"
        />
        <EmbeddedObjectInput.LabelledController
          label="Custom OAuth"
          control={control}
          name="customOauth"
          renderForm={(formProps) => <EmbeddedCustomOauthForm {...formProps} />}
        />
        <Button type="submit" disabled={isUpdating}>
          Save
        </Button>
      </form>
    </div>
  );
}

export default BrandEditForm;
