import { Badge, toast } from '@addglowapp/components';
import { BackButton, ErrorableLoader } from '@addglowapp/components';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetBrandMemberBrandOptionsQuery,
  useUpdateUserMutation,
  useUserEditByIdQuery,
} from '@src/generated/graphql';
import { UserFormData } from './user-schema';
import UserEditForm from './UserEditForm';

function UserEditPage(): JSX.Element {
  const { id } = useParams() as { id: string };

  const { data, error } = useUserEditByIdQuery({
    variables: { id },
  });

  const initialData: UserFormData | undefined = useMemo(() => {
    if (!data?.user) return undefined;
    return data.user;
  }, [data]);

  const {
    data: brandMemberBrandOptionsData,
    error: brandMemberBrandOptionsError,
  } = useGetBrandMemberBrandOptionsQuery();

  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();

  if (!initialData || !brandMemberBrandOptionsData) {
    return <ErrorableLoader error={error ?? brandMemberBrandOptionsError} />;
  }

  const submitData = async (formData: UserFormData): Promise<void> => {
    await updateUser({
      variables: {
        input: {
          id,
          data: {
            ...formData,
            username: formData.username ?? undefined,
          },
        },
      },
    });
    toast.success('Successfully updated item!');
    navigate('..');
  };

  const user = data?.user;

  return (
    <div className="space-y-4">
      <h1 className="flex space-x-2">
        <BackButton />
        <span>
          Edit&nbsp;
          <span className="inline-flex gap-2">
            <div>{user?.email}</div>
            {user?.customAuthBrand?.name && (
              <Badge>{user?.customAuthBrand?.name}</Badge>
            )}
          </span>
        </span>
      </h1>
      <UserEditForm
        submitData={submitData}
        initialData={initialData}
        brandMemberBrandOptions={brandMemberBrandOptionsData.brands}
      />
    </div>
  );
}

export default UserEditPage;
