import {
  Button,
  Dialog,
  TextInputField,
  SwitchField,
  toast,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateBrandMutation } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandFormData, brandEditFormSchema } from '../edit/brand-schema';
import { BrandShowCustomAuthForm } from '../show/BrandShowCustomAuthForm';

export function CreateBrandModal(): JSX.Element {
  const { handleSubmit, control, watch, setValue } = useForm<BrandFormData>({
    defaultValues: {
      name: '',
      shopUrl: '',
      communityDomain: '',
      analyticsKey: '',
      usesCustomAuth: false,
    },
    resolver: zodResolver(brandEditFormSchema),
  });
  const [createBrand] = useCreateBrandMutation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (data: BrandFormData): void => {
    createBrand({ variables: { input: { data } } })
      .then((data) => {
        const brandId = data.data?.createBrand?.brand?.id;
        if (!brandId) {
          throw new Error('Brand ID not found');
        }
        toast.success('Successfully created brand!');
        setIsModalOpen(false);
        navigate(`/brands/brands/${brandId}/show`);
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };
  const usesCustomAuth = watch('usesCustomAuth');

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <Dialog.Trigger asChild>
        <Button>Create Brand</Button>
      </Dialog.Trigger>
      <Dialog.Content className="max-h-[90vh] overflow-y-auto">
        <Dialog.Header>
          <Dialog.Title>Create a new brand</Dialog.Title>
        </Dialog.Header>
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <TextInputField.Controller
            label="Name"
            control={control}
            name="name"
          />
          <TextInputField.Controller
            label="Shop URL"
            control={control}
            name="shopUrl"
          />
          <TextInputField.Controller
            label="Community Domain"
            control={control}
            name="communityDomain"
          />
          <TextInputField.Controller
            label="Analytics Key"
            control={control}
            name="analyticsKey"
            description="Used for identifying the brand in analytics (must be all lowercase or dashes), e.g. addglow-community."
          />
          <SwitchField.Controller
            label="Uses custom authentication"
            control={control}
            name="usesCustomAuth"
            description="Use brand's identity provider instead of AddGlow's."
          />
          {usesCustomAuth && (
            <BrandShowCustomAuthForm control={control} setValue={setValue} />
          )}

          <Button type="submit">Create</Button>
        </form>
      </Dialog.Content>
    </Dialog>
  );
}
