import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFound.page';
import UsersRoutes from './users';

function AccountsFeatureRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="users/*" element={<UsersRoutes />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AccountsFeatureRoutes;
