'use client';

import { TextInputField, FormLabel, FormError } from '@addglowapp/components';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import {
  FocusEventHandler,
  KeyboardEventHandler,
  forwardRef,
  useMemo,
} from 'react';
import DatePicker from 'react-datepicker';
import {
  ChangeHandler,
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RefCallBack,
  UseFormRegisterReturn,
  useController,
} from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  className?: string;
  onChange: (newValue?: string | null) => void;
  onBlur?: () => void;
  value: string | null;
  showTimeSelect?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  isOptional?: boolean;
  minDate?: Date;
}

const DatePickerTextInput = forwardRef<
  HTMLInputElement,
  {
    onChange?: ChangeHandler;
    onClick?: () => void;
    onBlur?: ChangeHandler;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    value?: string;
    placeholder?: string;
    name?: string;
    disabled?: boolean;
    isOptional?: boolean;
  }
>(({ onChange, onClick, onBlur, name, disabled, ...rest }, ref) => (
  <TextInputField
    register={
      {
        ref: ref as RefCallBack,
        onChange,
        onBlur,
        name,
      } as UseFormRegisterReturn
    }
    disabled={disabled}
    {...rest}
  />
));

DatePickerTextInput.displayName = 'DatePickerTextInput';

function ReactDatePickerInput({
  className,
  onChange,
  onBlur,
  value,
  showTimeSelect,
  isClearable,
  disabled,
  isOptional,
  minDate,
}: Props): JSX.Element {
  const selectedDate = useMemo(() => (value ? parseISO(value) : null), [value]);
  return (
    <DatePicker
      className={clsx(disabled && 'cursor-not-allowed opacity-50', className)}
      onChange={(date) =>
        onChange(
          date &&
            (showTimeSelect ? date.toISOString() : format(date, 'yyyy-MM-dd')),
        )
      }
      minDate={minDate}
      onBlur={onBlur}
      selected={selectedDate}
      customInput={
        <DatePickerTextInput disabled={disabled} isOptional={isOptional} />
      }
      showTimeSelect={showTimeSelect}
      timeFormat="HH:mm"
      showPopperArrow
      dateFormat={showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
      isClearable={isClearable}
      placeholderText="Select a date"
      disabled={disabled}
      popperModifiers={[
        {
          name: 'arrow',
          options: {
            padding: ({ popper, reference }) => ({
              right: Math.min(popper.width, reference.width) - 24,
            }),
          },
        },
      ]}
    />
  );
}

interface ReactDatePickerInputLabelledProps extends Props {
  label?: React.ReactNode;
  error?: React.ReactNode;
}

ReactDatePickerInput.Labelled = function ReactDatePickerInputLabelled({
  label,
  className,
  error,
  isOptional,
  ...rest
}: ReactDatePickerInputLabelledProps): JSX.Element {
  return (
    <div className={className}>
      {label && <FormLabel isOptional={isOptional}>{label}</FormLabel>}
      <ReactDatePickerInput {...rest} />
      {error && <FormError>{error}</FormError>}
    </div>
  );
};

interface ReactDatePickerInputLabelledControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ReactDatePickerInputLabelledProps,
    'onChange' | 'onBlur' | 'value' | 'error'
  > {
  className?: string;
  control: Control<TFieldValues>;
  name: TFieldName;
}

ReactDatePickerInput.LabelledController =
  function ReactDatePickerInputController<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    className,
    name,
    control,
    isOptional,
    ...rest
  }: ReactDatePickerInputLabelledControllerProps<
    TFieldValues,
    TFieldName
  >): JSX.Element {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    return (
      <ReactDatePickerInput.Labelled
        {...rest}
        error={error?.message}
        onChange={(val) => {
          field.onChange(val as FieldPathValue<TFieldValues, TFieldName>);
        }}
        onBlur={field.onBlur}
        value={field.value as string}
        isOptional={isOptional}
      />
    );
  };

export { ReactDatePickerInput };
