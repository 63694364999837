import { ErrorableLoader } from '@addglowapp/components';
import { useGetBrandsQuery } from '@src/generated/graphql';
import { CreateBrandModal } from '../create/CreateBrandModal';
import BrandTable from './BrandTable';

function BrandListPage(): JSX.Element {
  const { data, error } = useGetBrandsQuery();

  return (
    <div className="space-y-4">
      <h1>Brands</h1>

      <CreateBrandModal />
      {!data ? (
        <ErrorableLoader error={error} />
      ) : (
        <BrandTable items={data.brands} />
      )}
    </div>
  );
}

export default BrandListPage;
