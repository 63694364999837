import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { createApolloClient } from '../services/apollo';

interface Props {
  children: React.ReactNode;
}

function AppApolloProvider({ children }: Props): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(
    () => createApolloClient({ getAccessToken: getAccessTokenSilently }),
    [getAccessTokenSilently],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default AppApolloProvider;
