import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../components';
import AdminLayout from '../components/AdminLayout';
import { useSession } from '../hooks/useSession';
import { identifySentryUser } from '../services/sentry';
import AccountsRoutes from './accounts';
import AuthRoutes from './auth';
import BrandsRoutes from './brands';
import BullBoardPage from './bull-board';
import Home from './Home';
import NotFoundPage from './NotFound.page';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function PagesRoot(): JSX.Element {
  const { userId } = useSession();

  useEffect(() => {
    if (!userId) return;

    identifySentryUser({
      id: userId,
    });
  }, [userId]);

  return (
    <SentryRoutes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="bull-board" element={<BullBoardPage />} />
      <Route path="auth/*" element={<AuthRoutes />} />

      <Route
        element={
          <RequireAuth>
            <AdminLayout />
          </RequireAuth>
        }
      >
        <Route path="accounts/*" element={<AccountsRoutes />} />
        <Route path="brands/*" element={<BrandsRoutes />} />
        <Route index element={<Home />} />
      </Route>
    </SentryRoutes>
  );
}

export default PagesRoot;
