import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFound.page';
import Auth0CallbackPage from './auth0-callback.page';
import SignupPage from './signup.page';

function AuthFeatureRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="auth0-callback" element={<Auth0CallbackPage />} />
      <Route path="signup" element={<SignupPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AuthFeatureRoutes;
