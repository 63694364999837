import { Button, SwitchField } from '@addglowapp/components';
import { Card } from '@addglowapp/components';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import { ReactDatePickerInput } from '@src/components/ReactDatePickerInput/ReactDatePickerInput';
import { BrandShowFragment } from '@src/generated/graphql';
import { useBrandEditForm } from './useBrandEditForm';

interface BrandShowTrialSettingsProps {
  className?: string;
  brand: BrandShowFragment;
}

export function BrandShowTrialSettings({
  className,
  brand,
}: BrandShowTrialSettingsProps): JSX.Element {
  const {
    handleSubmit,
    form: {
      control,
      formState: { isDirty },
      setValue,
      watch,
    },
  } = useBrandEditForm({ id: brand.id, initialData: brand });

  const isTrial = watch('isTrial');

  useEffect(() => {
    if (!isTrial) {
      setValue('trialEndsAt', null);
    }
  }, [isTrial, setValue]);

  return (
    <Card className={clsx('p-4', className)}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h3 className="font-semibold">Trial Settings</h3>
        <SwitchField.Controller
          label="Trial Community"
          control={control}
          name="isTrial"
          description="Enables trial period for the community."
        />
        <ReactDatePickerInput.LabelledController
          label="Trial End Period"
          control={control}
          name="trialEndsAt"
          showTimeSelect
          disabled={!isTrial}
        />
        <SwitchField.Controller
          label="Is Restricted"
          control={control}
          name="isRestricted"
          description="Restricts access to only brand members."
        />
        <Button type="submit" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </Card>
  );
}
