import { z } from 'zod';

export const embeddedCustomOauthFormSchema = z.object({
  clientId: z.string(),
  authorizeUrl: z.string().url(),
  accessUrl: z.string().url(),
  clientSecret: z.string(),
  jwksEndpointUrl: z.string().url(),
  jwtIssuer: z.string(),
});

export type EmbeddedCustomOauthFormData = z.infer<
  typeof embeddedCustomOauthFormSchema
>;

export const brandEditFormSchema = z.object({
  name: z.string(),
  shopUrl: z.string().nullish(),
  communityDomain: z
    .string()
    .regex(
      /^[a-z0-9.-]+(:[0-9]+)?$/,
      'Must contain only domain e.g. community.addglow.com',
    ),
  analyticsKey: z
    .string()
    .regex(
      /^[a-z0-9-_]+$/,
      'Must contain only lowercase alphanumeric characters, dashes, or underscores',
    )
    .nullish(),
  isRestricted: z.boolean().nullish(),
  isTrial: z.boolean().nullish(),
  trialEndsAt: z.string().nullish(),
  isPhoneVerificationEnabled: z.boolean().nullish(),
  usesCustomAuth: z.boolean(),
  customAuthUrl: z.string().url().nullish().or(z.literal('')),
  customLogoutUrl: z.string().url().nullish().or(z.literal('')),
  customUserSettingsUrl: z.string().url().nullish().or(z.literal('')),
  shouldShowPWA: z.boolean().default(false),
  customOauth: embeddedCustomOauthFormSchema.nullish(),
});

export type BrandFormData = z.infer<typeof brandEditFormSchema>;
