import { Sidebar } from '@addglowapp/components';
import clsx from 'clsx';
import { AiOutlineOrderedList } from 'react-icons/ai';
import { HiUsers } from 'react-icons/hi';
import { MdBusiness, MdHome, MdLogout } from 'react-icons/md';
import { Outlet } from 'react-router-dom';
import { useLogOut } from '@src/hooks/useLogOut';

interface Props {
  className?: string;
}

function AdminLayout({ className }: Props): JSX.Element {
  const logOut = useLogOut();

  return (
    <div className={clsx('flex h-full items-stretch', className)}>
      <Sidebar className="flex-none">
        <Sidebar.Header className="mb-4 space-y-2">
          <h1>Admin Dashboard</h1>
        </Sidebar.Header>
        <Sidebar.LinkGroup>
          <Sidebar.LinkItem Icon={MdHome} to="/">
            Home
          </Sidebar.LinkItem>
          <Sidebar.LinkItem Icon={MdBusiness} to="brands/brands">
            Brands
          </Sidebar.LinkItem>
          <Sidebar.LinkItem Icon={HiUsers} to="accounts/users/users">
            Users
          </Sidebar.LinkItem>
          <Sidebar.LinkItem Icon={AiOutlineOrderedList} to="/bull-board">
            Queues
          </Sidebar.LinkItem>
          <Sidebar.ButtonItem Icon={MdLogout} onClick={() => logOut()}>
            Log Out
          </Sidebar.ButtonItem>
        </Sidebar.LinkGroup>
      </Sidebar>
      <div className="flex flex-auto flex-col overflow-auto p-4">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
