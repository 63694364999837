import { z } from 'zod';

export const embeddedBrandMembershipsFormSchema = z.object({
  role: z.enum(['ADMIN', 'CUSTOMER', 'MODERATOR']),
  isBanned: z.boolean().nullish(),
  brandId: z.string().uuid(),
});

export type EmbeddedBrandMembershipsFormData = z.infer<
  typeof embeddedBrandMembershipsFormSchema
>;

export const userEditFormSchema = z.object({
  username: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  brandMemberships: z.array(embeddedBrandMembershipsFormSchema).nullish(),
});

export type UserFormData = z.infer<typeof userEditFormSchema>;
