import {
  Alert,
  Button,
  CheckedInput,
  LinkButton,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  useStatus,
  FormItem,
  SelectField,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  EmbeddedListFormProps,
  EmbeddedListTableProps,
} from '@src/components/EmbeddedListInput';
import { BrandMemberBrandOptionFragment } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import {
  EmbeddedBrandMembershipsFormData,
  embeddedBrandMembershipsFormSchema,
} from './user-schema';

interface TableProps
  extends EmbeddedListTableProps<EmbeddedBrandMembershipsFormData> {
  brandMemberBrandOptions: BrandMemberBrandOptionFragment[];
}

const roleOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Customer', value: 'CUSTOMER' },
  { label: 'Moderator', value: 'MODERATOR' },
];

interface FormProps
  extends EmbeddedListFormProps<EmbeddedBrandMembershipsFormData> {
  brandMemberBrandOptions: BrandMemberBrandOptionFragment[];
}

export function EmbeddedBrandMembershipsTable({
  items,
  edit,
  remove,
  brandMemberBrandOptions,
}: TableProps): JSX.Element {
  return (
    <Table className="max-w-6xl">
      <TableHeader>
        <TableRow>
          <TableHead>Role</TableHead>
          <TableHead>Is Banned</TableHead>
          <TableHead>Brand</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, idx) => (
          <TableRow key={item.id}>
            <TableCell>{item.role}</TableCell>
            <TableCell>{item.isBanned}</TableCell>
            <TableCell>
              {brandMemberBrandOptions.find(
                (option) => option.id === item.brandId,
              )?.name ?? 'Unknown Item'}
            </TableCell>
            <TableCell className="space-x-4">
              <LinkButton onClick={() => edit(idx)}>Edit</LinkButton>
              <LinkButton negative onClick={() => remove(idx)}>
                Remove
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function EmbeddedBrandMembershipsForm({
  initialData,
  onSubmit,
  brandMemberBrandOptions,
}: FormProps): JSX.Element {
  const { handleSubmit, control } = useForm<EmbeddedBrandMembershipsFormData>({
    resolver: zodResolver(embeddedBrandMembershipsFormSchema),
    defaultValues: initialData,
  });
  const { status, setError } = useStatus();

  const brandOptions = brandMemberBrandOptions.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e).catch((err) =>
          setError(logAndFormatError(err)),
        );
      }}
      className="space-y-4"
    >
      <Alert.WithStatus status={status} />
      <SelectField.Controller
        label="Role"
        control={control}
        name="role"
        options={roleOptions}
      />
      <CheckedInput.LabelledController
        label="Is Banned"
        control={control}
        name="isBanned"
      />

      <FormItem>
        <FormItem.Label>Brand</FormItem.Label>
        <SelectField.Controller
          control={control}
          name="brandId"
          label="Select a Brand"
          options={brandOptions}
        />
      </FormItem>

      <Button type="submit">Update</Button>
    </form>
  );
}
